// extracted by mini-css-extract-plugin
export var border = "_3n-5";
export var button = "_3n-g";
export var center = "_3n-7";
export var centered = "_3n-3";
export var closeButton = "_3n-9";
export var disabledWrapper = "_3n-h";
export var error = "_3n-k";
export var fill = "_3n-d";
export var footer = "_3n-c";
export var footerAction = "_3n-e";
export var footerButtons = "_3n-f";
export var header = "_3n-4";
export var icon = "_3n-j";
export var info = "_3n-n";
export var inline = "_3n-6";
export var large = "_3n-2";
export var main = "_3n-a";
export var modal = "_3n-0";
export var none = "_3n-o";
export var scrollable = "_3n-b";
export var small = "_3n-1";
export var success = "_3n-m";
export var tight = "_3n-i";
export var titleIcon = "_3n-8";
export var warn = "_3n-l";