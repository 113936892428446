// extracted by mini-css-extract-plugin
export var defaultWrapper = "_8a-3";
export var downloadButton = "_8a-4";
export var fileContainer = "_8a-7";
export var fileInfo = "_8a-5";
export var fileInfoWidthRestricted = "_8a-6";
export var fileType = "_8a-1";
export var fileTypeName = "_8a-2";
export var image = "_8a-0";
export var link = "_8a-9";
export var overflowEllipsis = "_8a-8";