// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "_9t-0";
export var footer = "_9t-2";
export var infoSection = "_9t-3";
export var infoSectionContainer = "_9t-4";
export var infoSectionTitleRow = "_9t-5";
export var itemDetails = "_9t-6";
export var line = "_9t-7";
export var lineItemImage = "_9t-1";
export var lineItems = "_9t-8";
export var modalContent = "_9t-9";
export var multiLineInfo = "_9t-a";
export var orderName = "_9t-b";
export var strong = "_9t-c";