// extracted by mini-css-extract-plugin
export var buttons = "_54-0";
export var buttonsActive = "_54-h";
export var cancelConfirm = "_54-1";
export var closeButton = "_54-2";
export var column = "_54-3";
export var container = "_54-4";
export var detailHeader = "_54-6";
export var detailTitle = "_54-7";
export var detailsContent = "_54-5";
export var header = "_54-8";
export var headerWithClose = "_54-9";
export var icon = "_54-a";
export var infoIcon = "_54-c";
export var informationContainer = "_54-b";
export var sideDrawer = "_54-d";
export var sideDrawerOpen = "_54-e";
export var stickyFooter = "_54-f";
export var typeOption = "_54-g";