// extracted by mini-css-extract-plugin
export var container = "_6u-0";
export var content = "_6u-3";
export var customerTitle = "_6u-4";
export var empty = "_6u-5";
export var gray = "_6u-6";
export var header = "_6u-7";
export var info = "_6u-8";
export var loading = "_6u-2";
export var loadingCard = "_6u-9";
export var oneLine = "_6u-a";
export var personName = "_6u-e";
export var pillContainer = "_6u-b";
export var selected = "_6u-1";
export var text = "_6u-c";
export var twoLines = "_6u-d";
export var unread = "_6u-f";
export var unreadIndicator = "_6u-g";