// extracted by mini-css-extract-plugin
export var animationContainer = "_7r-0";
export var conversationContainer = "_7r-1";
export var detailsContent = "_7r-2";
export var message = "_7r-3";
export var modalContent = "_7r-4";
export var modalFooter = "_7r-6";
export var modalHeader = "_7r-5";
export var name = "_7r-7";
export var optionContainer = "_7r-8";
export var optionTitle = "_7r-9";
export var pillContainer = "_7r-a";
export var selectedConversationsContainer = "_7r-b";
export var selectedOptionContainer = "_7r-c";