// extracted by mini-css-extract-plugin
export var createdDate = "_8o-1";
export var divider = "_8o-2";
export var headerDivider = "_8o-3";
export var messagesCard = "_8o-0";
export var messagesContainer = "_8o-7";
export var messagesContainerWrapper = "_8o-4";
export var pointerCapturingButtonBoxes = "_8o-a";
export var showLeftPanelButton = "_8o-9";
export var showMoreButton = "_8o-c";
export var showMoreButtonContainer = "_8o-b";
export var summaryTitle = "_8o-6";
export var systemMessageCardWrapper = "_8o-d";
export var topButtons = "_8o-8";
export var withPadding = "_8o-5";