// extracted by mini-css-extract-plugin
export var bubbleContent = "_6w-0";
export var dark = "_6w-2";
export var dot = "_6w-7";
export var firstDot = "_6w-9";
export var left = "_6w-3";
export var light = "_6w-1";
export var loadingBubble = "_6w-5";
export var loadingDot = "_6w-c";
export var loadingDotContainer = "_6w-6";
export var moveUpDown = "_6w-8";
export var right = "_6w-4";
export var secondDot = "_6w-a";
export var thirdDot = "_6w-b";