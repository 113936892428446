// extracted by mini-css-extract-plugin
export var copyableField = "_9g-4";
export var customerBadge = "_9g-0";
export var customerInfoTitle = "_9g-2";
export var details = "_9g-1";
export var dropdownContainer = "_9g-5";
export var dropdownImage = "_9g-6";
export var editAssigneeForm = "_9g-a";
export var editButton = "_9g-3";
export var email = "_9g-7";
export var hidden = "_9g-d";
export var input = "_9g-8";
export var saveButton = "_9g-b";
export var shopifyIcon = "_9g-c";
export var username = "_9g-9";