// extracted by mini-css-extract-plugin
export var colorSquare = "_a1-0";
export var danger = "_a1-5";
export var lightPink = "_a1-8";
export var neutralBlue = "_a1-7";
export var neutralYellow = "_a1-6";
export var normal = "_a1-1";
export var primary = "_a1-2";
export var primaryLight = "_a1-3";
export var solidBlack = "_a1-g";
export var solidGreen = "_a1-b";
export var solidLightBlue = "_a1-d";
export var solidNavy = "_a1-c";
export var solidOrange = "_a1-f";
export var solidPink = "_a1-e";
export var solidRed = "_a1-9";
export var solidYellow = "_a1-a";
export var success = "_a1-4";