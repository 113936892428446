// extracted by mini-css-extract-plugin
export var active = "_9h-b";
export var arrow = "_9h-8";
export var arrowContainer = "_9h-4";
export var container = "_9h-0";
export var disabled = "_9h-5";
export var dot = "_9h-a";
export var dotContainer = "_9h-9";
export var leftArrowContainer = "_9h-6 _9h-4";
export var rightArrowContainer = "_9h-7 _9h-4";
export var slide = "_9h-3";
export var sliderContainer = "_9h-1";
export var sliderOverflow = "_9h-2";