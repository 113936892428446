// extracted by mini-css-extract-plugin
export var actionButton = "_9z-1";
export var actions = "_9z-0";
export var copyableField = "_9z-2";
export var icon = "_9z-3";
export var overflowVisible = "_9z-4";
export var pillWrapper = "_9z-5";
export var productDetails = "_9z-7";
export var productPillsContainer = "_9z-8";
export var productTopDetails = "_9z-9";
export var products = "_9z-6";
export var returnDetails = "_9z-a";
export var strong = "_9z-b";
export var variantQuantityRow = "_9z-c";