// extracted by mini-css-extract-plugin
export var actionButton = "_a0-1";
export var actions = "_a0-0";
export var copyableField = "_a0-2";
export var detailColumn = "_a0-3";
export var detailRow = "_a0-4";
export var dropdownTitle = "_a0-5";
export var fulfillmentDetails = "_a0-7";
export var fulfillments = "_a0-6";
export var icon = "_a0-8";
export var imgContainer = "_a0-9";
export var lineItems = "_a0-a";
export var link = "_a0-p";
export var orderCard = "_a0-k";
export var orderCollapseButton = "_a0-l";
export var orderCollapseButtonIcon = "_a0-m";
export var orderDetails = "_a0-b";
export var orderPillsContainer = "_a0-c";
export var pillWrapper = "_a0-d";
export var product = "_a0-e";
export var productDetails = "_a0-g";
export var productTopDetails = "_a0-h";
export var products = "_a0-f";
export var strong = "_a0-i";
export var tightText = "_a0-o";
export var tooltipHidden = "_a0-q";
export var variantQuantityRow = "_a0-j";
export var wrappingText = "_a0-n";