// extracted by mini-css-extract-plugin
export var addButton = "_3z-0";
export var border = "_3z-8";
export var check = "_3z-h";
export var danger = "_3z-6";
export var dropdownContainer = "_3z-1";
export var editModalBody = "_3z-j";
export var footerContainer = "_3z-i";
export var fullWidth = "_3z-a";
export var hide = "_3z-9";
export var icon = "_3z-3";
export var input = "_3z-7";
export var inputFlex = "_3z-b";
export var inputWrapper = "_3z-c";
export var manualFocus = "_3z-5";
export var optionButton = "_3z-4";
export var pillWrapper = "_3z-e";
export var pointer = "_3z-2";
export var showOverflow = "_3z-f";
export var tagsFlex = "_3z-d";
export var xButton = "_3z-g";