// extracted by mini-css-extract-plugin
export var alternate = "_2o-h";
export var black = "_2o-3";
export var blue = "_2o-d";
export var blueLight = "_2o-c";
export var brand = "_2o-7";
export var error = "_2o-9";
export var gray = "_2o-6";
export var grayBlue = "_2o-b";
export var iconWrapper = "_2o-4";
export var indigo = "_2o-e";
export var orange = "_2o-g";
export var pink = "_2o-f";
export var primary = "_2o-0";
export var purple = "_2o-8";
export var success = "_2o-5";
export var warning = "_2o-a";
export var white = "_2o-1";
export var whiteSuccess = "_2o-2";