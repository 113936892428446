// extracted by mini-css-extract-plugin
export var box = "_2i-0";
export var check = "_2i-1";
export var checkboxButton = "_2i-6";
export var checkboxGroup = "_2i-5";
export var disabled = "_2i-7";
export var ghost = "_2i-9";
export var input = "_2i-2";
export var label = "_2i-4";
export var normal = "_2i-8";
export var uncheck = "_2i-3";