// extracted by mini-css-extract-plugin
export var actionButton = "_66-t";
export var buttonContainer = "_66-0";
export var buttonContainer2 = "_66-1";
export var buttonSection = "_66-2";
export var details = "_66-3";
export var dropdownItem = "_66-5";
export var dropdownItemsContainer = "_66-4";
export var fileInput = "_66-r";
export var headerButton = "_66-6";
export var icon = "_66-7";
export var input = "_66-8";
export var macroListItem = "_66-9";
export var mainContent = "_66-a";
export var microText = "_66-b";
export var modal = "_66-w";
export var optionsButton = "_66-c";
export var paperclip = "_66-s";
export var pillContent = "_66-d";
export var preview = "_66-e";
export var preview2 = "_66-f";
export var quillEditor = "_66-q";
export var selectedMacro = "_66-g";
export var sidebar = "_66-h";
export var sidebarHeader = "_66-j";
export var sidebarList = "_66-i";
export var sidebarListItem = "_66-k";
export var sidebarSearch = "_66-l";
export var snoozeDurationWrapper = "_66-v";
export var statusPillContainer = "_66-m";
export var tagsContainer = "_66-n";
export var unselectedMacro = "_66-o";
export var variable = "_66-u";
export var wrapper = "_66-p";