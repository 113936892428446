// extracted by mini-css-extract-plugin
export var aboveInput = "_8r-0";
export var actionButton = "_8r-5";
export var actionButtonContainer = "_8r-1";
export var actionIcon = "_8r-6";
export var aiInfoContainer = "_8r-2";
export var attachmentWrapper = "_8r-7";
export var bold = "_8r-3";
export var checkIcon = "_8r-8";
export var clickingRecipientBar = "_8r-11";
export var commentThreadSwitch = "_8r-a";
export var draftImage = "_8r-b";
export var draftImageContainer = "_8r-c";
export var draftImages = "_8r-e";
export var editButton = "_8r-g";
export var editor = "_8r-f";
export var editorContainer = "_8r-y";
export var editorErrorTooltipWrapper = "_8r-9";
export var headerDivider = "_8r-h";
export var icon = "_8r-i";
export var info = "_8r-j";
export var internal = "_8r-n";
export var internalDivider = "_8r-k";
export var internalMessage = "_8r-l";
export var messageInputCard = "_8r-m";
export var messageInputForm = "_8r-o";
export var messageInputFormInternal = "_8r-p";
export var options = "_8r-q";
export var publicMessageCustomer = "_8r-s";
export var publicMessageMerchant = "_8r-r";
export var removeImageButton = "_8r-d";
export var replyButtons = "_8r-t";
export var showSignatureButton = "_8r-u";
export var spinner = "_8r-v";
export var verticalButtonDivider = "_8r-4";
export var visibilitySelector = "_8r-w";
export var warning = "_8r-x";
export var wrappingText = "_8r-z";
export var writingInternalNoteTopBorder = "_8r-10";