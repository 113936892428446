// extracted by mini-css-extract-plugin
export var actions = "_2n-0";
export var actionsContainer = "_2n-3";
export var border = "_2n-1";
export var collapse = "_2n-2";
export var compact = "_2n-c";
export var content = "_2n-4";
export var contentChild = "_2n-8";
export var fullscreen = "_2n-6";
export var header = "_2n-9";
export var headerContainer = "_2n-e";
export var hideHeaderBorder = "_2n-d";
export var hidePadding = "_2n-5";
export var noScrollX = "_2n-f";
export var noScrollY = "_2n-g";
export var optOutTopPadding = "_2n-7";
export var profile = "_2n-h";
export var profileContainer = "_2n-i";
export var profileImage = "_2n-j";
export var profileMain = "_2n-k";
export var profileSubtitle = "_2n-m";
export var profileTitle = "_2n-l";
export var scrolledTop = "_2n-b";
export var tabsWrapper = "_2n-o";
export var title = "_2n-n";
export var withTabs = "_2n-a";