// extracted by mini-css-extract-plugin
export var brand = "_3p-4";
export var compact = "_3p-7";
export var extraSmall = "_3p-8";
export var icon = "_3p-0";
export var large = "_3p-1";
export var medium = "_3p-2";
export var outline = "_3p-9";
export var primaryDark = "_3p-5";
export var primaryLight = "_3p-3";
export var primaryModern = "_3p-6";