// extracted by mini-css-extract-plugin
export var acitonButtons = "_6m-0";
export var block = "_6m-1";
export var blockContent = "_6m-7";
export var blockDescription = "_6m-8";
export var blockIcon = "_6m-9";
export var blockTitle = "_6m-6";
export var border = "_6m-a";
export var center = "_6m-k";
export var edge = "_6m-b";
export var edgeLabel = "_6m-c";
export var edgePath = "_6m-d";
export var error = "_6m-e";
export var flowchart = "_6m-f";
export var flowchartContent = "_6m-g";
export var highlighted = "_6m-3";
export var hover = "_6m-4";
export var icon = "_6m-h";
export var left = "_6m-l";
export var navButton = "_6m-i";
export var navButtons = "_6m-j";
export var right = "_6m-m";
export var selected = "_6m-2";
export var small = "_6m-5";