// extracted by mini-css-extract-plugin
export var centered = "_2k-6";
export var flexible = "_2k-5";
export var footer = "_2k-k";
export var footerBorder = "_2k-l";
export var header = "_2k-c";
export var headerBorder = "_2k-d";
export var headerDefault = "_2k-g";
export var headerMedium = "_2k-h";
export var headerPadding = "_2k-e";
export var iconAndTitle = "_2k-f";
export var large = "_2k-4";
export var largePadding = "_2k-a";
export var medium = "_2k-3";
export var mediumPadding = "_2k-9";
export var modal = "_2k-0";
export var modalContainer = "_2k-b";
export var noPadding = "_2k-7";
export var skinny = "_2k-1";
export var small = "_2k-2";
export var smallPadding = "_2k-8";
export var subtitle = "_2k-j";
export var title = "_2k-i";