// extracted by mini-css-extract-plugin
export var baseCustomerActivityCardArrowRight = "_89-f";
export var baseCustomerActivityCardArrowRightContainer = "_89-e";
export var baseCustomerActivityCardContainer = "_89-c";
export var baseCustomerActivityCardContainerHovered = "_89-d";
export var conversationActivityDataConversationIconContainer = "_89-5";
export var customerActivityDataClaimIcon = "_89-8";
export var customerActivityDataClaimIconContainer = "_89-7";
export var customerActivityDataConversationIcon = "_89-6";
export var customerActivityDataConversationIconContainer = "_89-4";
export var customerActivityDataOrderIcon = "_89-1";
export var customerActivityDataOrderIconContainer = "_89-0";
export var customerActivityDataReturnIcon = "_89-3";
export var customerActivityDataReturnIconContainer = "_89-2";
export var customerActivityShoppingIconContainer = "_89-9";
export var messageBubble = "_89-a";
export var messageBubbleText = "_89-b";