// extracted by mini-css-extract-plugin
export var actionButton = "_9a-0";
export var bubble = "_9a-2";
export var bubbleContext = "_9a-6";
export var bubbleItems = "_9a-7";
export var customer = "_9a-3";
export var divider = "_9a-8";
export var fileList = "_9a-9";
export var infoAbove = "_9a-a";
export var internal = "_9a-4";
export var lightActionButton = "_9a-1";
export var link = "_9a-b";
export var merchant = "_9a-5";
export var message = "_9a-c";
export var profilePicture = "_9a-e";
export var quillToolbarContainer = "_9a-f";
export var senderDate = "_9a-g";
export var senderDateCustomer = "_9a-h";
export var senderName = "_9a-d";
export var time = "_9a-i";
export var uploadedImage = "_9a-j";
export var username = "_9a-k";
export var xsmall = "_9a-l";