// extracted by mini-css-extract-plugin
export var checkbox = "_4d-0";
export var circle = "_4d-3";
export var horizontalContainer = "_4d-7";
export var sm = "_4d-5";
export var switch0 = "_4d-2";
export var switchDisabled = "_4d-8";
export var switchEnabled = "_4d-1";
export var textSwitchContainer = "_4d-9";
export var textSwitchOption = "_4d-a";
export var textSwitchOptionSelected = "_4d-b";
export var verticalContainer = "_4d-6";
export var xs = "_4d-4";