// extracted by mini-css-extract-plugin
export var box = "_67-1";
export var checked = "_67-5";
export var descriptionSpacer = "_67-9";
export var disabled = "_67-4";
export var icon = "_67-6";
export var input = "_67-0";
export var labelSpacer = "_67-8";
export var medium = "_67-3";
export var small = "_67-2";
export var text = "_67-7";