// extracted by mini-css-extract-plugin
export var alignCenter = "_5s-b";
export var buttonContent = "_5s-0";
export var cardList = "_5s-1";
export var centerContent = "_5s-2";
export var chevron = "_5s-3";
export var closePanelButton = "_5s-i";
export var conversationDetailContainer = "_5s-4";
export var detailWrapper = "_5s-5";
export var dropdownButton = "_5s-c";
export var dropdownTitle = "_5s-7";
export var hidden = "_5s-8";
export var icon = "_5s-9";
export var panel = "_5s-g";
export var portalButtonsWrapper = "_5s-a";
export var right = "_5s-h";
export var tableSummaryContainer = "_5s-6";
export var tableSummaryHeader = "_5s-e";
export var threeDotsButton = "_5s-d";
export var wide = "_5s-f";