// extracted by mini-css-extract-plugin
export var attachment = "_9b-a";
export var emailContainer = "_9b-4";
export var emailContentPaddedContainer = "_9b-b";
export var emailHeader = "_9b-6";
export var emailInfoIcon = "_9b-9";
export var emailShortenedSummary = "_9b-e";
export var infoDropdown = "_9b-0";
export var infoRowBoundedContent = "_9b-d";
export var internal = "_9b-5";
export var merchant = "_9b-8";
export var messageInputContainer = "_9b-h";
export var normal = "_9b-7";
export var replyCard = "_9b-f";
export var shadowDomWrapper = "_9b-3";
export var subtleButton = "_9b-1";
export var subtleButtonIconContainer = "_9b-2";
export var toggleViewRepliesButton = "_9b-c";
export var wrappingText = "_9b-g";