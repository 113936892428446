// extracted by mini-css-extract-plugin
export var dark = "_4i-8";
export var iconHighlight = "_4i-0";
export var lg = "_4i-6";
export var light = "_4i-1";
export var md = "_4i-5";
export var modern = "_4i-9";
export var sm = "_4i-4";
export var xl = "_4i-7";
export var xs = "_4i-3";
export var xxs = "_4i-2";