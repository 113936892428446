// extracted by mini-css-extract-plugin
export var bubble = "_2r-0";
export var container = "_2r-3";
export var contrastBorder = "_2r-4";
export var greyOut = "_2r-2";
export var initialsWrapper = "_2r-1";
export var large = "_2r-9";
export var medium = "_2r-8";
export var mediumSmall = "_2r-7";
export var micro = "_2r-5";
export var small = "_2r-6";
export var tiny = "_2r-b";
export var xTiny = "_2r-a";